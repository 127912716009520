import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soulsoft20192',
  templateUrl: './soulsoft20192.component.html',
  styleUrls: ['./soulsoft20192.component.css']
})
export class Soulsoft20192Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
