import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { MissaoComponent } from './missao/missao.component';
import { Soulsoft20192Component } from './eventos/soulsoft20192/soulsoft20192.component';
import { Soulsoft20192PalestrantesComponent } from './eventos/soulsoft20192/soulsoft20192-palestrantes/soulsoft20192-palestrantes.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    MissaoComponent,
    Soulsoft20192Component,
    Soulsoft20192PalestrantesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
